import React from 'react'
import styled from 'styled-components'
import { Fade } from 'react-reveal';
import Zoom from 'react-reveal/Zoom';
import '../teststyles.css';
import '../pageStyles.css';

const MainContent = ({heading, subHeading, heroImage, imageContainerClass}) => {
  return (
    <div style={{'paddingTop':'90px'}}>
        <Headline className='flex-column'>
            <Zoom top cascade>
              <h2 style={{'color': '#A1A1A6'}}>{heading}</h2>
            </Zoom>
            <Fade top cascade>
              <h2 style={{'color':'white'}}>{subHeading}</h2>
            </Fade>
          </Headline>
          <ImageContainer className={imageContainerClass}>
            <img src= {heroImage}></img>
          </ImageContainer>  
    </div>
  )
}

export default MainContent

const Headline = styled.div`
    gap: 2px;
    text-align: center;
    h2{
      font-size: 45px;
    }
    @media (max-width: 1280px) {
    h2 {
      font-size: 40px; 
    }
  }
    @media (max-width: 768px) {
    h2 {
      font-size: 36px; 
    }
  }
;
`

const ImageContainer = styled.div`
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 10px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    margin-top: 50px; 
    padding: 40px;
  }
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;
