import React from 'react'
import '../teststyles.css'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

const ProjectHeroText = ({title, desc, link, buttonColor}) => {
  return (
    <Text>
        <h3>{title}</h3>
        <p className='project-desc'>{desc}</p>
        <div class="btn-contain">
          <div className={`btn-underline-contain ${buttonColor}`}>
            {/* <a href="http://mediamilitia.com" class="btn-underline"> */}
            <Link to={'/'+link} className = "btn-underline"><span>Read More</span></Link>
            {/* </a> */}
            </div>
        </div>
  </Text>
  )
}

export default ProjectHeroText

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 420px;
  .project-desc{
    font-size: 1.0rem;
    line-height: 1.16666667;
    letter-spacing: .009em;
    font-weight: 500;
    font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    color: #a1a1a6;
    @media screen and (min-width: 768px) {
    font-size: 1.25rem;
  }
  }

  h3{
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.005em;
    line-height: 1.01714285714;
    font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    -webkit-animation: gradient 20s linear infinite;
            animation: gradient 20s linear infinite;
    background: linear-gradient(45deg, rgb(135, 39, 255) -100%, rgb(157, 68, 253) -50%, rgb(255, 159, 225) 0%, rgb(157, 68, 253) 50%, rgb(135, 39, 255) 100%);
    background-size: 200% 100%;
    -webkit-background-clip: text;
            background-clip: text;
    color: #f5f5f7;
    @media screen and (min-width: 768px) {
      font-size: 1.0; 
    }
  }
`

