import React from 'react'
import '../Footer.css';
import styled from 'styled-components';
import { Bounce } from 'react-reveal';
import Flip from 'react-reveal/Flip';

const Footer = () => {
  return (
      <Container>
        <Bounce left cascade>
          <h3>Let's connect.</h3>
        </Bounce>
        <Flip left cascade>
          <h4>Let's build technology to make lives <span className='gradient footer'>colorful</span>.</h4>
        </Flip>
        <div class="container">
        <a href="mailto:ananyathukral@outlook.com"><i class="fa fa-envelope" id="email"></i></a>
        <a target="_blank" href="https://www.linkedin.com/in/ananya-thukral-576301178/"><i class="fa fa-linkedin" id="linkedin"></i></a>
        <a target="_blank" href="https://github.com/AnanyaThukral"><i class="fa fa-github-square github" id="github"></i></a>
        </div>
    </Container>
  )
}

export default Footer

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 40px;
  gap: 10px;
  h3{
    color: #A1A1A6;
  }
  h4{
    color: #f5f5f7;
    font-size: 20px;
  }
`