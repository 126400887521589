import {React, useState} from 'react'
import styled from 'styled-components';
// react reveal animations
import { Fade, Slide } from 'react-reveal';
import '../components/Overview';
// stylesheets
import '../Tracker.css';
import '../teststyles.css';
import {BsGraphUp} from 'react-icons/bs';
import {MdOutlineKeyboardArrowRight} from 'react-icons/md'
import { ReadMore } from '@mui/icons-material';
import Reflection from './Reflection';


const Research = () => {
    // const [readMore , setReadMore] = useState(false);

    // const handleReadMoreClick = (event) => {
    //     event.preventDefault();
    //     setReadMore(!readMore);
    // };

    const [expandedStates, setExpandedStates] = useState([false, false]); 

    const handleReadMoreClick = (index,event) => {
        event.preventDefault(); 
        setExpandedStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };

  return (
    <ResearchContainer>
        <Fade top>
            <ProjectBox>
                <div class="heading">Research</div>
            </ProjectBox> 
        </Fade> 
        <TextContainerOne>
                <div style={{'display':'flex', 'flexDirection':'column', 'gap': '40px', 'flex':'2'}}>
                    <p className='textp'>Jassi, among many other international students in Canada. <span style={{'color': '#a1a1a6'}}>Shares the common goal of obtaining Permanent Residency (PR) after completing their studies.</span></p>
                    <p className='textp' style={{'color': '#a1a1a6'}}> The path often taken to achieve this is through <span style={{'color':'white'}}>post-graduation work permits</span>, allowing students to gain Canadian work experience and subsequently become eligible for PR.</p>
                </div>
                
                <ResearchGoal>
                    <div style={{'display':'flex', 'flexDirection':'column', 'gap':'30px'}}>
                        <h3>Research Goal.</h3>
                        <p className='textp'>To investigate why international students in Canada are having difficulty obtaining the Post-Graduation Work Permit (PGWP).</p>
                    </div>
                        <img src='./images/bitri.png'></img>
                </ResearchGoal>
        </TextContainerOne>
        
        {/* secondary research start */}

        <div id='secondary-research'>
            <Fade left>
                <ProjectBox>
                    <div class="subheading">Secondary Research</div>
                </ProjectBox> 
            </Fade> 
        </div>
     
        <SecondaryResearchContainer >
            <StatsContainer>
                <TextContainer>
                    <span style={{'color':'white'}}>PGWP</span>
                    <span>A Key to Permanent Residency for International Students in Canada.</span>
                    <p>The data suggests that 84% of international students who successfully obtain permanent residency in Canada do so by utilizing the Post-Graduation Work Permit Program (PGWPP) after completing their studies.</p>
                </TextContainer>
                <img src='./images/chart.png'></img>
            </StatsContainer>
            <StatsContainer>
                <TextContainer>
                    <span style={{'color':'white'}}>Data Sources.</span>
                    <p>To gain a comprehensive understanding of the most common reasons why international students in Canada become ineligible for Post-Graduation Work Permits (PGWPs), I gathered data from various sources.</p>
                </TextContainer>
                <img src='./images/secondaryresearchsources.png'></img>
            </StatsContainer>        
            <StatsContainer>
                <TextContainer>
                    <span style={{'color':'white'}}>Data Categorization.</span>
                    <p>Analyzed the data and identified themes and patterns. To make the analysis process more efficient, I assigned different codes to the data based on their respective themes/patterns.</p>
                </TextContainer>
                <img src='./images/secondaryresearch.png'></img>
            </StatsContainer>
            <StatsContainer highlighted>
                <TextContainer>
                    <span  style={{'color':'#f5f5f7'}}><BsGraphUp style={{'marginRight':'0.5em'}}/>User Trends Discovered</span>
                    <p>54% of concerns and issues related to Post-Graduate Work Permit (PGWP) among international students are related to maintaining Full-time status during their program. </p>
                    <p>This is a major pain point for them as it is a requirement set by the Immigration, Refugees and Citizenship Canada (IRCC) to be eligible for PGWP.</p>
                </TextContainer>
                <img src='./images/secondaryresearchdataanalysis.png'></img>
            </StatsContainer>
            <RedefniningResearchGoal>
                <div style={{'display':'flex', 'flex-direction':'column', 'gap':'20px', 'marginBottom':'50px'}}>
                    <h2 style={{'color':'#A1A1A6'}}>Narrowing the Scope.</h2>
                    <h3 style={{'color':'#f5f5f7'}}>Redefining Research Goal.</h3>
                </div>
                <TextContainer style={{'gap': '10px'}}>
                    <p className='textp'>Secondary research helped me to discover a critical piece of information:</p>
                    <p style={{'color':'white'}}>The challenge of maintaining full-time status for international students in Canada.</p>
                </TextContainer>
                <ResearchGoal style={{'marginTop': '50px'}}>
                    <div style={{'display':'flex', 'flexDirection':'column', 'gap':'30px'}}>
                        <p className='title'>Revised.</p>
                        <h3>Research Goal.</h3>
                        <p className='textp'>To investigate the challenges faced by international students in maintaining full-time status during their studies in Canada, and how this impacts their eligibility for the Post-Graduation Work Permit (PGWP).</p>
                    </div>
                        <img src='./images/bitri.png'></img>
                </ResearchGoal>
            </RedefniningResearchGoal>

            <div id='primary-research'>
            <Fade left> 
                    <ProjectBox>
                        <div class="subheading" style={{'fontSize': '55px'}}>Primary Research</div> 
                    </ProjectBox> 
                </Fade> 
            </div>
                 
                    
        </SecondaryResearchContainer>
        {/* secondary research end */}

        {/* primary research start */}
        <PrimaryResearchContainer>
            <DataContainers>
                <DataContainer  expanded={expandedStates[0]}
                className={`data-container ${expandedStates[0] ? 'expanded' : ''}`}>
                    <p>48</p>
                    <h3>Survey Results</h3>
                    <hr aria-orientation="horizontal" className="divider" style={{'marginBottom':'60px'}}></hr>
                    <ul>
                        <h3 style={{'fontSize': '18px' }}>Questions</h3>
                        <li>What is their current year of Study?</li>
                        <li>Have they done any Part-time study semester?</li>
                        <li>How many students know the eligibility requirements for work permit?</li>
                        {expandedStates[0] && (
            <>       <li>What percentage of students are willing to apply for a work permit after graduation?</li>
                        {/* Add more additional list items as needed */}
                    </>
                )}
                                    <a href="#" onClick={(event) => handleReadMoreClick(0, event)}>
                    {expandedStates[0] ? 'Read Less' : 'Read More'}
                </a>
            </ul>
           
                </DataContainer>
    
                <DataContainer expanded={expandedStates[1]}  className={`data-container ${expandedStates[1] ? 'expanded' : ''}`}>
                    <p>7</p>
                    <h3>User Interviews</h3>
                    <hr aria-orientation="horizontal" className="divider" style={{'marginBottom':'60px'}}></hr>
                    <ul>
                        <h3 style={{'fontSize': '18px'}}>Questions</h3>
                        <li>Are they aware of negative imapacts of Part time studies as an International Student?</li>
                        <li>Have they dropped any courses that resulted in Part time studies?</li>
                       
                        {expandedStates[1] && (
            <> <li>Did they reach out to Program Advisor or International Advisor before dropping courses?</li>
            <li>How many students are satisfied with the information received by the University International Advisors on immigration?</li>
                        {/* Add more additional list items as needed */}
                    </>
                )}
                <a href="#" onClick={(event) => handleReadMoreClick(1, event)}>
                    {expandedStates[1] ? 'Read Less' : 'Read More'}
                </a>
            </ul> 
                </DataContainer>
            </DataContainers>
            <PrimaryResearchDateInsights>                                                                                                                                                                                                
                <h2>187 Data Points.</h2>
                <p>Translated the feedback obtained from surveys and interviews into a substantial set of data points for analysis and synthesis.</p>
                <Slide bottom>
                    <ImageContainer>
                        <img src='./images/datapoints1.png'></img>
                    </ImageContainer>
                </Slide>
            </PrimaryResearchDateInsights> 
            <PrimaryResearchDateInsights>
                <h2>Sorting Data into Dynamic Buckets.</h2>
                <p>Constructed an affinity map, categorizing key findings into distinct buckets. From these buckets, built 2 buckets: 'Pains' and 'Gains' that further guided ideation.</p>
                <Slide bottom>
                    <ImageContainer>
                        <img src='./images/affinitymap2.png'></img>
                    </ImageContainer>                    
                 </Slide>
            </PrimaryResearchDateInsights>
            <StatsContainer highlighted style={{'marginTop': '0'}}>
                <TextContainer> 
                <span style={{'color':'white'}}><BsGraphUp style={{'marginRight':'0.5em'}}/>Key Data Insights</span>
                <ul>
                    <li>Students only look for eligibility requirements when they are close to graduation.</li>
                    <li>Students are uncertain about how their eligibility for the Post-Graduation Work Permit (PGWP) is affected when they drop courses or enroll in fewer courses.</li>
                    <li>Students are unsure how Full Time/Part Time status could impact their eligibility.</li>
                </ul>
                </TextContainer>
                {/* <ImageContainer className="custom-image-container"> */}
                    <img src='./images/affinitymap3.png'></img>
                {/* </ImageContainer> */}
            </StatsContainer>
        </PrimaryResearchContainer>  
        {/* primary research end */}



        {/* ideation start*/}
        <IdeationContainer id = 'ideation'>

            <ProjectBox>
                    <div class="heading">Ideation</div>
            </ProjectBox> 

            <LofiPrototype>

                <FeaturesContainer>
                    <Feature>
                            <h3>Feature: Student Empowerment Hub</h3>     
                    </Feature>
                    <ImageContainer style={{'max-width': '1200px'}}>
                        <img src='./images/trackerlofi.png'></img>
                    </ImageContainer>
                    <DesignDecisionContainer>
                        <h3>Design Decision</h3>
                        <DesignDecision>   
                                <ul style={{'gap': '10px'}}>
                                    <li>1. Connects like-minded students striving to comprehend PGWP eligibility.</li>
                                    <li>2. Provides access to diverse groups and channels sharing PGWP requirement insights.</li>
                                </ul>
                        </DesignDecision>
                    </DesignDecisionContainer>
                </FeaturesContainer>


                <FeaturesContainer>
                        <Feature>
                            <h3>Feature: Semester Progress Tracker for FT/PT Status</h3>     
                        </Feature>
                    <ImageContainer style={{'width': '1200px'}}>
                        <img src='./images/trackerlofi2.png'></img>
                    </ImageContainer>
                    <DesignDecisionContainer>
                        <h3>Design Desision</h3>
                        <DesignDecision>
                            <BottomContainer>
                            <ul style={{'gap': '10px'}}>
                                    <li>1. <span style={{'color':'white'}}>Input Courses:</span> Students input enrolled courses and credits per semester.</li>
                                    <li>2. <span style={{'color':'white'}}>Auto Calculation: </span>System calculates total credits and FT/PT status.</li>
                                    <li>3. <span style={{'color':'white'}}>Real-time Updates: </span>Adjusts instantly for dropped courses, reflecting eligibility changes.</li>
                                    <li>4. <span style={{'color':'white'}}>Reminders: </span>Notifies students of FT/PT importance and alerts for eligibility impact.</li>
                                </ul>
                            </BottomContainer>
                        </DesignDecision>
                    </DesignDecisionContainer>
                </FeaturesContainer>
            </LofiPrototype>
            
        </IdeationContainer>

        <DesignIterationContainer>
            <h2>Witness the progression towards refined designs.</h2>
            <IterationLinkContainer>
                <a target="_blank" href='https://www.figma.com/proto/xd6q8wvpaCGX1sgL4nLXmz/intL-Tracker-(Iteration-1)?type=design&node-id=185-635&t=PxiIMaDIi8VlT5yG-1&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=185%3A635&show-proto-sidebar=1&mode=design'>Iteration 1<MdOutlineKeyboardArrowRight style={{'fontSize':'21px' }}/></a>
                <a target="_blank" href='https://www.figma.com/proto/jR743uqPJjXtVEK7ARg5bk/Tracker(2nd-Iteration)?node-id=185-635&starting-point-node-id=185%3A635&mode=design&t=XA9mdt8mlYxck106-1'>Iteration 2 <MdOutlineKeyboardArrowRight style={{'fontSize':'21px' }}/></a>
            </IterationLinkContainer>
        </DesignIterationContainer>
    {/* Ideation end*/}   


    {/* prototype start */}
    <PrototypeContainer id = 'prototype'>


        <ProjectBox>
                    <div class="heading">Prototypes</div>
            </ProjectBox> 

        <Fade>
        <Prototype>
            <video src="./images/tracker_connect.mp4" autoPlay loop muted></video>
            {/* <img src='./images/tracker_status.gif'></img> */}
            <FeatureContent>
               <h2>Connect</h2>
               <ul>
                    <li>Connects students pursuing similar immigration goals.</li>
                    <li>Direct conversations between students and mentors for advice and assistance.</li>
                    <li>Provides private and public channels for learning about eligibility and seeking help.</li>
                    <li>Involves university advisors in certain channels to offer expert guidance throughout the immigration process.</li>
               </ul>
            </FeatureContent>
        </Prototype>
        </Fade>
       
       <Fade>
        <Prototype>
        <video src="./images/tracker_status.mp4" muted autoPlay loop></video>
            <FeatureContent>
                        <h2>Status (Iteration 1)</h2>
                        <ul>
                    <li>Determines whether the semester is full-time (eligible for work permit) or part-time (not eligible).</li>
                    <li>Monitors dropped courses during the semester and recalculates credits.</li>
                    <li>Alerts students about potential risks associated with dropped courses.</li>
                    <li>Offers a comprehensive semester overview displaying total credits and status (full-time/part-time).</li>
               </ul>
                    </FeatureContent>
            </Prototype>
       </Fade>

        <Fade>
        <Prototype>
             <video src="./images/tracker_status2.mp4" muted autoPlay loop></video>
            <FeatureContent>
                <h2>Status (Improved)</h2>
                <ul>
                    <li>Replaced the modal-based semester overview with a dedicated page view.</li>
                    <li><span style={{'color':'white'}}>Recommendations Section:</span> Provides guidance on how to navigate challenges related to part-time semesters.</li>
                    <li><span style={{'color':'white'}}>Letter Upload Feature:</span> Added the functionality for students to upload counselor letters for part-time semesters.</li>
                    <li><span style={{'color':'white'}}>Course Overview: </span>Displays a comprehensive view of registered and dropped courses for the specific semester.</li>
               </ul>
                </FeatureContent>
        </Prototype>
        </Fade>


        <PrototypeLinkSection>
            <h2>Curious?</h2>
            <a target="_blank" href='https://www.figma.com/proto/ExzFQosambpuGnlnkNRFnJ/Tracker(3rd-Iteration)?type=design&node-id=3-12&t=wDT3AIjyd6XDIowN-1&scaling=contain&page-id=0%3A1&starting-point-node-id=185%3A635&mode=design'>Check Out the Figma Prototype Here (Iteration 3) <MdOutlineKeyboardArrowRight style={{'fontSize':'21px' }}/></a>
        </PrototypeLinkSection>

    </PrototypeContainer>

    {/* prototype end */}



             
    </ResearchContainer>
  )
}

export default Research

const ResearchContainer = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1280px) {
    }
    ul{
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 30px;
       
    }
    li{
        font-size: 20px;
        line-height: 1.4211026316;
        font-weight: 400;
        letter-spacing: .012em;
        font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
        color: #a1a1a6;
        @media (max-width: 1280px) {
      font-size: 18px;
    }
}
`

const TextContainerOne = styled.div`
    display: flex;
    flex-direction: column;
    gap: 100px;
    max-width: 1200px;
    justify-content: center;
    margin: 100px 0;
    @media (max-width: 768px) {
    width: 80%;
    p{
      font-size: 18px;
    }
}
    @media (max-width: 1280px) {
    align-items: center;
    text-align: center;
    width: 80%;
    margin: 50px auto;
  }
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
    p{
    font-size: 20px;
    line-height: 1.4211026316;
    font-weight: 400;
    letter-spacing: .012em;
    font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    color: #a1a1a6;
    }
    @media (max-width: 1280px) {
       p{
        font-size: 18px;
       }
    @media (max-width: 768px) {
      max-width: 300px;
    }
    }
`

const ImageContainer = styled.div`
    img{
        height: 100%;
        width: 100%;
    }
    margin-top: 20px;
    @media (max-width: 768px) {
        margin-top: 0;
    }

    @media (max-width: 1280px) {
        margin-top: 0;
        img{
            max-width: 70%;
        }
    }
`

const StatsContainer = styled.div`
    background-color: #151516;
    background: ${props =>
        props.highlighted
            ? 'linear-gradient(90deg,#000000 -50%,#3533cd)'
            : '#151516'
    };
    max-width: 1200px;
    min-height: 600px;
    border-radius: 30px;
    color: white;
    overflow: hidden;
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 40px;
    margin-top: 100px;
    span{
        font-size: 25px;
        line-height: 1.05;
        font-weight: 700;
        color: rgba(255,255,255,0.6);
        font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif
    }
    img{
       max-height: 500px;
    }
  @media (max-width: 1280px) {
    max-width: 800px;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 100px;
    h3 {
      font-size: 30px;
    }
    img {
        max-height:400px;
        margin-top: 50px;
    }
     span{
        font-size: 20px;
        }
  };

  
    @media (max-width: 768px) {
    text-align: center; 
    flex-direction: column;
    min-height: auto;
    h3 {
      font-size: 20px; 
    }
    p {
      font-size: 15px; 
    }
    img{
        height: 150px;
    }
  }


/* Media query for laptop screen width */
@media (min-width: 1024px) {
    .custom-image-container img {
        max-width: 100%; /* Adjust the width as needed */
    }
}
`

const ResearchGoal = styled.div`
    display: flex;
    gap: 30px;
    background-image:linear-gradient(90deg,#000000 -50%,#3533cd);
    border-radius: 30px;
    padding: 40px;
    justify-content: center;
    align-items: center;
    img{
        height: 300px;
    }
    h3{
        font-weight: 700;
        line-height: 1.1;
        font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    }
    p{
        line-height: 1.4211026316;
        font-weight: 400;
        letter-spacing: .012em;
        font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
        color: white;
    }
    .title{
        font-size: 18px;
        line-height: 1.19048;
        letter-spacing: .011em;
        font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
        color: #A1A1A6
    }

    @media (max-width: 768px) {
    text-align: center; /* Center the content on small screens */
    flex-direction: column;
    h3 {
      font-size: 20px; /* Adjusted font size */
    }
    p {
      font-size: 15px; /* Adjusted font size */
    }
    img {
    display: 100px;/* Adjusted max height for the image */
    }
  }
  @media (max-width: 1280px) {
    max-width: 800px;
    h3 {
      font-size: 30px;
    }
    img {
      max-height: 200px;
    }
  };
`
const ProjectBox = styled.div`
    margin-top: 150px;
    text-align: center;
    @media (max-width: 1280px) {
        text-align: center;
    }
`

const RedefniningResearchGoal = styled.div`
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 1200px;
    h2{
        font-size: 50px;
        line-height: 1.05;
        font-weight: 700;
        letter-spacing: -.015em;
    }
    h3{
        font-size: 50px;
    line-height: 1.05;
    font-weight: 700;
    letter-spacing: -.015em;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    }
    @media (max-width: 1280px) {
        max-width: 800px;
        h2{
            font-size: 40px;
        }
        h3{
            font-size: 30px;
        }
        img{
            max-height: 200px;
        }
    }
        @media (max-width: 768px) { 
        font-size: 20px;
        max-width: 400px;
        h3{
            font-size: 20px;
        }

    }
`


const PrimaryResearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    margin-top: 100px;
`

const DataContainers = styled.div`
    display: flex;
    gap: 50px;
    @media (max-width: 768px) { 
        flex-direction: column;
    }
`

const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #151516;
    border-radius: 30px;
    padding: 40px;
    max-width: 550px;
     height: ${props => props.expanded ? 'auto' : 'fit-content'};
    p{
        font-size: 55px;
        line-height: 1.05;
        font-weight: 700;
        letter-spacing: -0.015em;
        color: #fff;
    }
    h3{
        font-size: 28px;
        line-height: 1.14286;
        font-weight: 500;
        letter-spacing: .007em;
    }
     a{
        color: white;
            font-size: 18px;
            margin-top: 30px;
            text-decoration: none;
    line-height: 1.381;
    font-weight: 400;
    letter-spacing: .011em;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
        :hover{
            text-decoration: underline;
        }
}
    @media (max-width: 1280px) { 
        max-width: 400px;
        h3 {
            font-size: 20px;
        }
    }

`



const PrimaryResearchDateInsights = styled.div`
    max-width: 1200px;
    border-radius: 30px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
     h2{
        font-size: 50px;
        line-height: 1.05;
        font-weight: 700;
        letter-spacing: -.015em;
    }
    p{
    font-size: 24px;
    line-height: 1.4211026316;
    font-weight: 400;
    -webkit-letter-spacing: .012em;
    -moz-letter-spacing: .012em;
    -ms-letter-spacing: .012em;
    letter-spacing: .012em;
    font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    color: #a1a1a6;
    }

    @media (max-width: 1280px) {
    text-align: center; /* Center the content on small screens */
    h2{
        font-size: 40px;
        }
    p {
     font-size: 18px;
    }
    img {
        max-height:auto;
    }
  }
`

const LofiPrototype = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;
    gap: 150px;
    h3{
        font-size: 32px;
        line-height: 1.05;
        font-weight: 700;
        letter-spacing: -.015em;
    }
    p{
        color: #a1a1a6;;
    }
  @media (max-width: 1280px) {
    align-items: center;
    }
    @media (max-width: 768px) {
        
    }
`

const FeaturesContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 1280px) {
     align-items: center;
     text-align: center;
     h3{
        font-size: 25px;
     }
}
@media (max-width: 768px) {
     h3{
        font-size: 20px;
     }
     p{
        font-size: 18px;
    }
    img{
       max-width: 40%;
    };

}
`

const Feature = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 1280px) {
    margin-bottom: 10px;
    }
`

const DesignDecisionContainer = styled.div`
    display: flex;
    gap: 100px;
    margin-top: 50px;
    @media (max-width: 1280px) {
        flex-direction: column;
        gap: 50px;
}
`

const DesignDecision = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
    max-width: 300px;
}
`

const BottomContainer = styled.div`
    ul{
        display: flex;
    }
`

const SecondaryResearchContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const PrototypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
`

const FeatureContent = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  gap: 30px;
  h2{
        font-size: 40px;
		color: white;
		display: inline-block;
		color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		background-image: linear-gradient(45deg,#ccefff,#60cafc 50%,#2f65ff);
		will-change: transform;
		padding-top: .5px;
		overflow: hidden;
		background-color: #ffb6ff 0%;
  }
  p{
    font-size: 20px;
    line-height: 1.16667;
    font-weight: 400;
    letter-spacing: 0.009em;
    font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgb(161, 161, 166);
  }
  @media (max-width: 1280px) {
    text-align: center;
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    width: 80%;
    p{
      font-size: 18px;
    }
    @media (max-width: 1280px) {
    align-items: center;
    margin-top: 70px;
    text-align: center;
    width: 80%;
    margin: 50px auto;
  }
    
  }
`

const Prototype = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 200px;
    video {
        max-height: 600px;
    }
    img{
        max-height: 600px;
    }
    @media (max-width: 1280px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    video{
        max-height:500px;
    } 
    margin-top: 100px;
}
    @media (max-width: 768px) {
        video{
        max-height:300px;
    } 
    }
`

const IdeationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const PrototypeLinkSection = styled.div`
max-width: 616px;
text-align: center;
margin-top: 150px;
display: flex;
gap:22px;
flex-direction: column;
    h2{
        color: #f5f5f7;
      font-size: 64px;
        line-height: 1.0625;
        font-weight: 600;
        letter-spacing: -.009em;
        font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    }
    a{
        color: #2997ff;
            font-size: 21px;
            text-decoration: none;
    line-height: 1.381;
    font-weight: 400;
    letter-spacing: .011em;
    display: flex;
    align-items: center;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
        :hover{
            text-decoration: underline;
        }
}
      @media (max-width: 1280px) {
       max-width: 500px;
       h2{
          font-size: 34px;
       }
}
`

const DesignIterationContainer = styled.div`
margin-top: 100px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    text-align:center;
     h2{
        color: #f5f5f7;
      font-size: 24px;
        line-height: 1.0625;
        font-weight: 600;
        letter-spacing: -.009em;
        font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    }
`

const IterationLinkContainer = styled.div`
    display: flex; 
    gap: 10px;
    a{
        color: #2997ff;
            font-size: 21px;
            text-decoration: none;
    line-height: 1.381;
    font-weight: 400;
    letter-spacing: .011em;
    display: flex;
    align-items: center;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
        :hover{
            text-decoration: underline;
        }
    }
`
