import styled from 'styled-components'
import Fade from 'react-reveal/Fade';
import data from '../data.json';
import '../teststyles.css';
import ProjectHeroText from '../components/ProjectHeroText';
import {HiArrowsRightLeft} from 'react-icons/hi2'
import Footer from '../components/Footer';


const WorkPage = () => {


  return (
    <Container>
      <div>
      <HeadingText>
        <h2><span className='gradient'>Product Designer</span> with a background in development.</h2>
        <h2>Passionate to design for social good.</h2>
      </HeadingText>
      <TestContainer>
        <TextContainer style={{'gap':'30px'}}>
          <p className='desc'>Ananya <HiArrowsRightLeft style={{'color':'#9aa0a6'}}/> अनन्या </p>
          <p className='desc' style={{'fontStyle':'italic'}}>Unique</p>
          <p className='desc'>I'm Ananya, and just like my name suggests, I bring a unique perspective to every product and design.</p>
          <p className='desc'> I thrive on finding the uncommon angles, crafting solutions that stand out in a sea of ordinary.</p>
        </TextContainer>
        
        <ImageContainer>
          <img src='./images/testcolor.png'></img>
        </ImageContainer> 
      </TestContainer>
      </div>
     
<Fade left>
  <ProjectBox>
        <div class="gradient-text">My Work</div>
  </ProjectBox> 
</Fade>


{/* Project Gallery Section Start */}

  <Projects>
    <Fade>
        <div className='projectContainer project1'>
            <div className="imac">
                <div className="screen">
                  <div className="viewport" style={{"backgroundImage" : "url('./images/test1.png')"}}></div>
                </div>
                <div className="logo"></div>
                <div className="leg"></div>
                <div className="foot"></div>
            </div>
            <ProjectHeroText title = {data.projects[0].title} desc = {data.projects[0].desc} link = {'tracker'}/>
      </div>
    </Fade>

    <Fade>
      <div className= 'projectContainer project3'>
      <div className='responsive-img'>
        <img src='./images/Yozutest.png'></img>
      </div>
      <ProjectHeroText title = {data.projects[1].title} desc = {data.projects[1].desc} link = {'foodcycling'} buttonColor="green-button"/>
      <div className='overlay'>
        <p className='overlay-content'>
       Almost Ready!{' '}
          <span className="cooking-icon" role="img" aria-label="Cooking">
      🍳
    </span>
        </p>
    </div>
      </div>
    </Fade>
    
    {/* <Fade>
      <div className= 'projectContainer project2'>
      <div className='responsive-img'>
        <img src='./images/Yozutest.png'></img>
      </div>
      <ProjectHeroText title = {data.projects[1].title} desc = {data.projects[1].desc} link = {'yozu'}/>
      <div className='overlay'>
        <p className='overlay-content'>
          Coming Soon!{' '}
          <span className="rocket-icon" role="img" aria-label="Rocket">
            🚀
          </span>
        </p>
    </div>
      </div>
    </Fade> */}



  </Projects>
  <Footer/>  
  </Container>
  )
}

export default WorkPage




const Container = styled.div`
/* background-color: #f5f5f7; */
background-color: black;
position: relative;
max-width: 1600px;
margin: 0 auto;
@media screen and (max-width: 768px) {
   padding: 20px;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2; /* Place above the rest of the content */
}

.overlay-content {
    color: #f5f5f7;
    font-size: 20px;
    text-align: center;
    padding: 20px; /* Add padding for better visibility */
  border-radius: 10px; /* Rounded corners for the overlay */
  background-color: transparent;
}



.underline-magical {
      background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%);
      background-repeat: no-repeat;
      background-size: 100% 0.2em;
      background-position: 0 88%;
      transition: background-size 0.25s ease-in;
      &:hover {
        background-size: 100% 88%;
      }
}

.color{
  background: linear-gradient(90deg, red, orange, silver, green, blue, purple);
      background-clip: text;
      -webkit-background-clip: text;
      margin: auto;
      color: transparent;
      &:hover {
        background-size: 100% 88%;
      }
}

.vision{
    color: #f5f5f7;
    font-size: 21px;
    line-height: 1.33;
    font-weight: 400;
    letter-spacing: .011em;
    .underline-magical{
      background: linear-gradient(90deg, red, orange, yellow, green, blue, purple);
      background-clip: text;
      -webkit-background-clip: text;
      margin: auto;
      color: transparent;
    }
  }
  .desc{
    margin-left: 100px;
    font-size: 1.4rem;
    line-height: 1.75;
    color: #86868b;
    max-width: 700px;
    line-height: 1.1666666667;
    letter-spacing: .009em;
    font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    opacity: .999;
    display: flex;
    align-items: center;
    gap: 10px;

@media screen and (max-width: 1280px) {
    font-size:1.2rem; 
}


    
  }
  .contain{
    display: flex;
  }
  .demo a {
	position: absolute;
	bottom: -110px;
	left: 47%;
	z-index: 2;
	display: inline-block;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	color: #6e6e73;
	font: normal 400 20px/1 "Josefin Sans", sans-serif;
	letter-spacing: 0.1em;
	text-decoration: none;
	transition: opacity 0.3s;
}
  .demo a:hover {
	opacity: 0.5;
}


@-webkit-keyframes sdb10 {
	0% {
		-webkit-transform: translate(0, 0);
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	80% {
		-webkit-transform: translate(0, 20px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes sdb10 {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	80% {
		transform: translate(0, 20px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

`

const ImageContainer = styled.div`
  height: 690px;
  margin-top: 30px;
  max-width: 600px;
  img{
    width: 100%;
    height: auto;
    display: block;
  }
  @media screen and (max-width: 1280px) {
    height: auto; /* Adjust height for smaller screens */
    max-width: 100%; /* Adjust max-width for smaller screens */
    padding: 20px;
  };
`

const Content = styled.div`
  background-color: black;
  height: 110px;
  padding-left: 290px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const ProjectSection = styled.div`
background-color: #f5f5f7;
background-color: black;
position: absolute;
margin-top: 100px;
margin-left: 290px;
  h2{
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -0.009em;
    color: #1d1d1f;
  }
`

const Projects = styled.div`
  margin-left: 180px;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  gap: 70px;
  margin-top: 60px;
  margin-bottom: 150px;
  @media screen and (max-width: 768px) {
     margin-left: 0;
     align-items: center;
  }
`

const TestContainer = styled.div`
  display: flex;
  margin-top: 50px;
  /* max-width: 1900px; */
  gap: 70px;
  @media screen and (max-width: 768px) {
    flex-direction: column; /* Stack text above image for smaller screens */
  }
  
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media screen and (max-width: 768px) {
    max-width: 100%; /* Adjust width for smaller screens */
  }
`


const HeadingText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 2rem;
  margin-left: 100px;
  padding-top: 90px;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.25;
  color:#f5f5f7;
    /* Adjust font size for smaller screens */
    @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 1280px) {
    font-size: 1.5rem;
  }
`

const ProjectBox = styled.div`
  background-color: #fafafa;
  background: radial-gradient(#fff 50%, #aaa) fixed;
  height: 100px;
  width: 830px;
  margin-left: 100px;
  position: absolute;
  margin-top: -80px;
  padding-left: 40px;
  @media screen and (max-width: 768px) {
    width: 100%; /* Adjust the width for smaller screens */
    margin-left: 0; /* Reset the left margin */
    padding-left: 20px; /* Adjust the padding for smaller screens */
    position: relative; /* Reset the position */
    margin-top: 0; /* Reset the top margin */
    text-align: center; /* Center the content on smaller screens */
  }
`

const ProjectTestContainer = styled.div`
  margin-top: 200px;
  height: 600px;
  display: grid;
  grid-gap: 5rem;
  grid-template-columns: 500px 600px;
  width: 85%;
  padding: 50px;
  align-items: center;
`

