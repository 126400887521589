import React from 'react'
import styled from 'styled-components';

const Reflection = () => {
  return (
      <Container id = 'reflection'> 
        <LeftContainer>
          <h2>Reflections</h2>
        </LeftContainer>
        <RightContainer>
          <p className='subtitle'>Initiative led to Impact</p>
          <p>As an international student in Canada, I noticed fellow students struggling with work permit eligibility due to full-time study requirements. Despite initial setbacks when university advisors lacked resources, I embraced the challenge. Taking the initiative, I embarked on a personal design project to address this issue.</p>
          <p>Engaging extensively with international students, I conducted usability sessions showcasing prototypes. Witnessing their excitement and relief reinforced the potential impact of my solution. Their recurring question about the solution's launch timeline affirmed that I was moving in the right direction.</p>
          <p>Later, my project earned approval from the HCI faculty at the University of Guelph. This endorsement propelled me to further refine my solution through user studies and design iterations.</p>
        </RightContainer>
    </Container>
  )
}

export default Reflection

const Container = styled.div`
  background-color: #f1f1f1;
  margin-top: 200px;
  padding-top: 200px;
  padding-bottom: 200px;
  display: flex;
  justify-content: center;
  gap: 100px;
  @media (max-width: 768px) {
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   padding: 30px;
  };
`

const LeftContainer = styled.div`
  h2{
    color: #1d1d1f;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.07143
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 500px;
  .subtitle{
    font-size: 20px;
    line-height: 1.125;
    font-weight: 600;
    letter-spacing: .004em;
    color: rgb(29, 29, 31);
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    width: 100%;
  };
`