import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
       <Nav>
      <NavContainer>
        <LeftContainer>
          <Link to = "/" className='nav-links'>
            <p>अनन्या.</p>
          </Link>
        </LeftContainer>
        <RightContainer>
          <ul>
              <li>
                <Link to="/" className = "nav-links">Work</Link>
              </li>
              <li>
                <Link to="/about" className = "nav-links">About Me</Link>
              </li>
              <li>
                <a target="_blank" href="https://drive.google.com/file/d/1tpsTmSlxyu-Ftzihk9L9NTe4bUNteBM6/view?usp=sharing" className="nav-links" target="_blank" rel="noopener noreferrer">
                  Resume
                </a>
              </li>
          </ul>
        </RightContainer>
      </NavContainer>
    </Nav>
  )
}

export default Header

const Nav = styled.div`
    position: sticky;
    background-color: rgba(32, 33, 36, 0.7);
    z-index: 10;
    top: 0;
    backdrop-filter: saturate(180%) blur(20px);
    height: 48px;
    padding: 15px 30px;
`

const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1600px;
    margin: 0 auto;
`

const LeftContainer = styled.div`
    h1{
        color: rgba(255, 255, 255, .6);
        font-size: 25px;
        font-size: 1em;
        font-weight: 600;
        line-height: 1.08;
    };p{
      font-size: 1.0rem;
      color: rgba(255, 255, 255, .6);
      font-weight: 600;
      &:hover {
          color: #2997ff; /* Change color on hover */
        }
    }
`

const RightContainer = styled.div`
    ul{
        display: flex;
        list-style: none;
        gap: 40px;
    }
    a{
        cursor: pointer;
        text-decoration: none;
        color:  rgba(255, 255, 255, .6);
        transition: color 0.3s ease; /* Add transition for smooth color change */
        &:hover {
          color: #2997ff; /* Change color on hover */
        }
    }
`
