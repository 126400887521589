import React from 'react';
import '../teststyles.css';
import { Link } from 'react-scroll';

const StickySideNav = () => {


    
  return (
    <nav className="sticky-nav">
    <ul>
        <li><Link to="overview" smooth={true}>Overview</Link></li>
        <li><Link to="solution-glimpse" smooth={true} >Solution Glimpse</Link></li>
        <li><Link to="secondary-research" smooth={true}>Secondary Research</Link></li>
        <li><Link to="primary-research" smooth={true}>Primary Research</Link></li>
        <li><Link to="ideation" smooth={true} >Ideation</Link></li>
        <li><Link to="prototype" smooth={true}>Prototypes</Link></li>
        <li><Link to="reflection" smooth={true}>Reflections</Link></li>
    </ul>
    </nav>
  );
};

export default StickySideNav;





