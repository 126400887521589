import React from 'react'
import styled from 'styled-components'
import { Fade } from 'react-reveal';
import Zoom from 'react-reveal/Zoom';
import '../teststyles.css';
import '../pageStyles.css';

const SolutionGlimpse = ({featureData, projectID}) => {

    const gapSize = projectID === "project_1" ? '0px' : '100px';

  return (
        <Container>
          <div style={{ gap: '10px' }} className='flex-column-center'>
            <Zoom>
              <h2>Sneak Peek.</h2>
            </Zoom>
            {/* <p>How "Jassi" benefits from the solution?</p> */}
            <p>Quick glipmse of the main features.</p>
          </div>
    
          <FeatureSection>
            {featureData.map((feature, index) => (
              <Fade key={index}>
                <FeatureContainer gapSize = {gapSize}>
                  <Fade>
                    <FloatingImageContainer>
                      <img src={feature.featureMockUp} alt={`Feature ${index + 1}`} />
                    </FloatingImageContainer>
                  </Fade>
                  <FeatureContent>
                    <h2>{feature.featureTitle}</h2>
                    <p>{feature.featureDesc}</p>
                  </FeatureContent>
                </FeatureContainer>
              </Fade>
            ))}
          </FeatureSection>
        </Container>
      );
}

export default SolutionGlimpse

const Container = styled.div`

padding-top: 100px;

  h2{
   font-size: 40px;
   color: white;
   display: inline-block;
   color: transparent;
   -webkit-background-clip: text;
     background-clip: text;
   background-image: linear-gradient(45deg,#ccefff,#60cafc 50%,#2f65ff);
   will-change: transform;
    padding-top: .5px;
   overflow: hidden;
    background-color: #ffb6ff 0%;
   background-image: linear-gradient(45deg, rgb(93, 46, 255) -100%, rgb(157, 68, 253) -50%, rgb(188, 115, 233) 0%, rgb(157, 68, 253) 50%, rgb(93, 46, 255) 100%);
   font-size: 45px;
   @media (max-width: 1280px) {
    font-size: 35px;
   }
  }

  p{
    color: #a1a1a6;
     font-size: 26px;
    @media (max-width: 1280px) {
    font-size: 20px;
   }
}
`

const FeatureSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  gap: 100px;
    @media (max-width: 1280px) {
    margin-top: 0;
  }
    @media (max-width: 768px) {
    gap: 50px;
  }
`

const FeatureContent = styled.div`
  max-width: 335px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  h2{
    color: #f5f5f7;
    font-size: 38px;
    line-height: 1.16667;
    font-weight: 600;
    letter-spacing: -0.005em;
    font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
  }
  p{
    font-size: 20px;
    line-height: 1.16667;
    font-weight: 400;
    letter-spacing: 0.009em;
    font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgb(161, 161, 166);
  }
  @media (max-width: 1280px) {
    /* align-items: center; */
    margin-top: 70px;
    /* text-align: center; */
    h2{
        font-size: 24px;
        color: #f5f5f7;
    }
    p{
        font-size: 18px;
        color: rgb(161, 161, 166);
    }
  }
  @media (max-width: 768px) {
    width: 80%;
    p{
      font-size: 18px;
    }
    @media (max-width: 1280px) {
    align-items: center;
    margin-top: 70px;
    text-align: center;
    width: 80%;
    margin: 50px auto;
  }
    
  }
`

const FeatureContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
  gap: ${(props) => props.gapSize};
  @media (max-width: 768px) {
    margin-top: 50px;
    flex-direction: column;
  }
  @media (max-width: 1280px) {
    justify-content: center;
  }
`


const FloatingImageContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  overflow: hidden;
  /* animation: float 3s ease-in-out infinite; */

  @media (max-width: 1280px) {
    max-width: 700px;
  }

  img {
    width: 100%;
  }

  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px); 
    }
  }
`;