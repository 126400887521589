import React from 'react';
import '../Gallery.css';
import styled from 'styled-components';

const Gallery = () => {
  return (
    <Container>
      <section className="gallery">
        <div className="container">
          <div className="grid">
            {/* First column */}
            <div className="column-xs-12 column-md-4">
              <figure className="img-container">
                <img src="./images/about8.jpeg" alt="Smart Watch" />
              </figure>
            </div>
            {/* Second column */}
            <div className="column-xs-12 column-md-4">
              <figure className="img-container">
                <img src="./images/about2.jpeg" alt="Camera Film" />
              </figure>
            </div>
            {/* Third column */}
            <div className="column-xs-12 column-md-4">
              <figure className="img-container">
                <img src="./images/about10.jpeg" alt="Coffee" />
              </figure>
            </div>
            {/* Fourth column */}
            <div className="column-xs-12 column-md-6">
              <figure className="img-container">
                <img src="./images/about3.jpeg" alt="Phone" />
              </figure>
            </div>
            {/* Fifth column */}
            <div className="column-xs-12 column-md-6">
              <figure className="img-container">
                <img src="./images/about6.jpeg" alt="Keyboard" />
              </figure>
            </div>
            {/* Sixth column */}
            <div className="column-xs-12">
              <figure className="img-container">
                <img src="./images/about1.jpeg" alt="Wrist Watch" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default Gallery;

const Container = styled.div`
    margin-top: 100px;
    
img {
  width: 100%;
  height: 500px;
  -o-object-fit: cover;
     object-fit: cover;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}
img:hover {
  transform: scale(1.05);
}
`
