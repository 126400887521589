import './App.css';
import './teststyles.css';
import styled from 'styled-components';
import Header from './components/Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop';
import WorkPage from './pages/WorkPage';
import TrackerPage from './pages/TrackerPage';
import YozuPage from './pages/YozuPage';
import Foodcycling from './pages/Foodcycling';
import ScrollUp from './components/ScrollUp';
import CustomCursor from './components/CustomCursor';
import AboutMe from './pages/AboutMe';
import Footer from './components/Footer';


function App() {
  return (
    <Container className = 'dark-mode'>
      <Router>
      <CustomCursor />
      <Header/>
        <ScrollUp/>
        <Routes>
          <Route path= '/work' element = {<WorkPage/>}/>
          <Route path= '/about' element = {<AboutMe/>} />
          <Route path= '/tracker' element = {<TrackerPage/>}/>
          <Route path= '/yozu' element = {<YozuPage/>}/>
          <Route path= '/foodcycling' element = {<Foodcycling/>}/>
          <Route path= '' element = {<WorkPage/>}/>
        </Routes>
      </Router> 
      <ScrollToTop/>
    </Container>
  );
}

export default App;

const Container = styled.div`
background-color: black;
`

