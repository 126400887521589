import React from 'react'

const YozuPage = () => {
  return (
    <div>
      yozu project
    </div>
  )
}

export default YozuPage
