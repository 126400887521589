import React, { useState, useEffect } from 'react';
import '../teststyles.css';

const CustomCursor = () => {
  const [cursorPos, setCursorPos] = useState({ x: -100, y: -100 });

  useEffect(() => {
    const updateCursorPos = (e) => {
      setCursorPos({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', updateCursorPos);

    return () => {
      window.removeEventListener('mousemove', updateCursorPos);
    };
  }, []);

  return (
    <div
      className="custom-cursor"
      style={{ left: cursorPos.x, top: cursorPos.y }}
    />
  );
};

export default CustomCursor;
