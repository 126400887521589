import React from 'react'
import styled from 'styled-components'
import '../Tracker.css';
import '../teststyles.css';
import { Animator, ScrollContainer, ScrollPage, batch,  FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, ZoomIn, ZoomOut } from "react-scroll-motion";
import Research from '../components/Research';
import Overview from '../components/Overview';
import Reflection from '../components/Reflection';
import { useEffect } from 'react';
import StickySideNav from '../components/StickySideNav';
import Footer from '../components/Footer';

import data from '../data.json';
import MainContent from '../components/MainContent'
import SolutionGlimpse from '../components/SolutionGlimpse';



const TrackerPage = () => {



  useEffect(() => {
    const navigation = document.querySelector('.sticky-nav');
    const targetSection = document.querySelector('#solution-glimpse'); 

    const handleScroll = () => {
      if (window.innerWidth > 1680 && window.scrollY >= targetSection.offsetTop) {
        navigation.style.display = 'block';
      } else {
        navigation.style.display = 'none';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, );

  return (
   
    <Container>
  
     <ScrollContainer>
     
     <MainContent heading = {data.projects[0].title} subHeading = {data.projects[0].subheading} heroImage = {data.projects[0].heroImage} imageContainerClass="project1"/>
     <Overview title = {data.projects[0].title} desc={data.projects[0].context.desc} team={data.projects[0].context.team} duration={data.projects[0].context.duration} role={data.projects[0].context.role} time={data.projects[0].context.time} boldDesc={true} />
     <SolutionGlimpse id = 'solution-glimpse' featureData = {data.projects[0].solution} projectID={data.projects[0].projectID}/>
      
   
     
     {/* research section start */}
 

     <StickySideNav />
<Research/>


 {/* research section end */}


{/* reflection section start */}


      <Reflection/>


      <Footer/>
     
     </ScrollContainer>

    </Container>
 
  )
}

export default TrackerPage

const Container = styled.div`
background-color: black;
color: white;
overflow: hidden;
`

const Headline = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    text-align: center;
    h2{
      font-size: 50px;
    }
    p{
      font-size: 23px;
      line-height: 1.14286;
      font-weight: 400;
      letter-spacing: .007em;
      font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
      max-width: 800px;
      color: #A1A1A6;   
      margin-top: 50px;
     
    }

    @media (max-width: 1280px) {
    /* Styles for screens with a width of at least 768px */
    h2 {
      font-size: 45px; 
    }
    p {
      font-size: 18px;
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }
    @media (max-width: 768px) {
    /* Styles for screens with a width of at least 768px */
    h2 {
      font-size: 36px; 
    }
    p {
      font-size: 18px;
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }
;
`

const ImageContainer = styled.div`
    width: 90%;
    max-width: 1350px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 10px;
    box-sizing: border-box;
       /* @media (max-width: 1280px) {
    margin-top: 50px; 
    padding: 40px;
  } */
    @media (max-width: 768px) {
    margin-top: 50px; 
    padding: 40px;
  }
  
  img{
    max-width: 100%;
    height: auto;
    display: block;

  }

`
