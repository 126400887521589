import React from 'react'
import styled from 'styled-components'
import { Fade } from 'react-reveal';
import '../pageStyles.css';
import '../teststyles.css';

const Overview = ({title, desc, team, duration, role, time, boldDesc }) => {

  const formatDescription = (description) => {
    if (boldDesc) {
      const boldPart = "an exclusive application that helps international students keep track of their work permit requirements while studying and ensures they're eligible for a work permit after graduation.";

      const parts = description.split(boldPart);

      return (
        <React.Fragment>
          {parts[0]}
          <span className='font-bold'>{boldPart}</span>
          {parts[1]}
        </React.Fragment>
      );
    } else {
      return description;
    }
  };

  return (
    <Container id='overview' className='font-light flex-horizontal-center'>
      <ProjectTitle className='font-bold'>
      <Fade top cascade>
        <p>{title}</p>
      </Fade>
      </ProjectTitle>
      <ProjectContent className='flex-column'>
        <ProjectDesc>{formatDescription(desc)}</ProjectDesc>
        <ProjectDetails className='flex-row'>
            <DetailContainer className='flex-column'>
                <DetailContainerTitle>Team</DetailContainerTitle>
                <p>{team}</p>
            </DetailContainer>
            <DetailContainer className='flex-column'>
                <DetailContainerTitle>Duration</DetailContainerTitle>
                <p>{duration}</p>
            </DetailContainer>
            <DetailContainer className='flex-column'>
                <DetailContainerTitle>Role</DetailContainerTitle>
                <p>{role}</p>
            </DetailContainer>
            <DetailContainer className='flex-column'>
                <DetailContainerTitle>Time</DetailContainerTitle>
                <p>{time}</p>
            </DetailContainer>
        </ProjectDetails>
      </ProjectContent>
    </Container>
  )
}

export default Overview

const Container = styled.div`
background-color: #f1f1f1;
    gap: 100px;
    line-height: 150%;
    margin-top: 200px;
    padding-top: 200px; 
    padding-bottom: 200px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
    padding: 50px 20px;
  }

  /* @media (min-width: 481px) and (max-width: 767px) {
    padding: 30px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 40px;
  }

  @media (min-width: 1025px) and (max-width: 1280px){
    padding: 70px;    
        }   */


;

`
const ProjectTitle = styled.div`
    line-height: 1;
    p{
        font-size: 27px;
    }
    @media (max-width: 786px) {
    p{
      font-size: 1.25rem;
    }
}
`

const ProjectContent = styled.div`
    gap: 50px;
    p{
        font-size: 18px;
    }
    @media (max-width: 768px) {
    gap: 15px;
  }
`

const ProjectDesc = styled.div`
    width: 650px;
    color:  #0f0602;
    font-size: 18px;
    @media (max-width: 768px) {
    font-size: 14px;
    width: 100%;
  };
`

const ProjectDetails = styled.div`
    gap: 70px;
  @media (max-width: 1024px) {
    gap: 15px;
  }
`
const DetailContainer = styled.div`
    gap: 10px;
    p{
      font-size: 18px;
      max-width: 200px;
    }
    @media (max-width: 1024px) {
    gap: 50px;
  }
`

const DetailContainerTitle =   styled.div`
    font-size: 18px;
    color: #0f0602;
`
