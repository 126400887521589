import React from 'react'
import styled from 'styled-components'

const Foodcycling = () => {
  return (
    <Container>
      {/* <img style = {{'height':'800px'}} src="/images/foodcycling1.gif" alt="Food Cycling"/> */}
      {/* <img style = {{'height':'800px'}} src="/images/foodcycling2.gif" alt="Food Cycling"/> */}
      {/* <img style = {{'height':'800px'}} src="/images/foodcycling3.gif" alt="Food Cycling"/> */}
      {/* <img style = {{'height':'800px'}} src="/images/foodcycling4.gif" alt="Food Cycling"/> */}
    </Container>
  )
}

export default Foodcycling

const Container = styled.div`
margin-top: 100px;
`