import React from 'react'
import styled from 'styled-components'
import ImageGallery from '../components/ImageGallery'
import Footer from '../components/Footer'



const AboutMe = () => {
  return (
   <Container>
    <TextContainer>
      <h2>Code, Peaks, and UX Magic: My Story</h2>
      <p>Picture this: I was a developer, meticulously constructing the digital landscape with lines of code. But deep down, there was a craving for something more – something that could transform the ordinary into the extraordinary.</p>
      <p>Then, amidst the whirl of travel, I found it – a missing piece that would reshape my journey. As I embarked on adventures amidst tranquil mountain peaks and scaled their heights, I realized that the way I saw the world could reshape how I crafted digital interactions.</p>
      <p>Just as hiking up a mountain involves preparation, navigation, and challenges that lead to a sense of accomplishment, UX design follows a similar path. It's about guiding users through their unique journeys, understanding their perspectives, and ensuring their experiences are seamless and rewarding.</p>
      <p>This tale isn't about leaving behind my developer days; it's about fusing them with my newfound passion. It's about creating designs that tell stories, evoke emotions, and transport users to uncharted territories of delight. From coding's syntax to design's symphony, the transition was a harmonious evolution – one that continues to unfold with each journey, each design, each unique experience.</p>
    </TextContainer>
    <ImageGallery/>
    <Footer/>  
   </Container>
  )
}

export default AboutMe

const Container = styled.div`
h2{
    color: white;
}
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
  max-width: 600px;
  margin-top: 100px;
  p{
    color: #fff;
    font-size: 17px;
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
`